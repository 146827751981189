import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'modal',
    'form',
    'company',
    'companySelect',
    'tag',
    'selectedCompaniesInput',
    'selectedTagsInput'
  ];
  
  connect() {
    this.selectedTagsList = [];
    this.selectedCompaniesList = [];
    this.attachListeners();
  }
  
  $companyDropdownText() {
    return $(this.companySelectTarget).find('.filter-dropdown-text');
  }
  
  $companyContainer() {
    return $(this.companySelectTarget).find('#dropdown-content');
  }
  
  $tagContainer() {
    return $(this.formTarget).find('#tags-container');
  }
  
  $allTagsContainer() {
    return $(this.formTarget).find('#all_tags');
  }
  
  $selectedCompanies() {
    return $(this.formTarget).find('#filter_companies');
  }
  
  $selectedTags() {
    return $(this.formTarget).find('#filter_tags');
  }
  
  $clearButton() {
    return $(this.formTarget).find('.offer-modal-action-clear');
  }
  
  attachListeners() {
    const self = this;
    this.initialCompaniesFromQuery();
    this.initialTagsFromQuery();
    $(this.formTarget).on('submit', (e) => self.handleFormSubmit(e));
    $(this.companySelectTarget).on('click', () => self.toggleDropdown());
    this.$companyContainer().on('click', (e) => self.onCompanySelected(e));
    this.$tagContainer().on('click', (e) => self.onTagSelected(e));
    this.$clearButton().on('click', (e) => self.onClearClicked(e));
  }
  
  toggleDropdown() {
    this.$companyContainer().toggle();
  }
  
  initialCompaniesFromQuery() {
    const params = new URLSearchParams(window.location.search);
    const companies = params.get('filter_companies');
    
    if (companies) {
      this.selectedCompaniesList = companies.split(',');
      this.selectedCompaniesList.forEach(company => {
        const companyElement = this.companyTargets.find(element => element.dataset.id === company);
        if (companyElement) {
          companyElement.classList.add('selected');
        }
      });
      this.selectedCompaniesInputTarget.value = this.selectedCompaniesList.join(',');
      
      const selectedCompanyNames = Array.from(this.selectedCompaniesList).map(cId => {
        const companyElement = $(this.companySelectTarget).find(`.company-option[data-id='${cId}']`);
        return companyElement.text();
      });
      this.$companyDropdownText().text(selectedCompanyNames.join(','));
    }
  }
  
  initialTagsFromQuery() {
    const params = new URLSearchParams(window.location.search);
    const tags = params.get('filter_tags');
    
    if (tags) {
      this.selectedTagsList = tags.split(',');
      this.selectedTagsList.forEach(tag => {
        const tagElement = this.tagTargets.find(element => element.dataset.tag === tag);
        if (tagElement) {
          tagElement.classList.add('active');
        }
      });
      this.selectedTagsInputTarget.value = this.selectedTagsList.join(',');
    }
  }
  
  handleFormSubmit(e) {
    e.preventDefault();
    
    const form = e.target;
    const formData = new FormData(form);
    const params = new URLSearchParams(window.location.search);
    
    for (const [key, value] of params.entries()) {
      if (!formData.has(key)) {
        formData.append(key, value);
      }
    }
    
    const queryString = new URLSearchParams(formData).toString();
    
    window.location.href = `${form.action.split('?')[0]}?${queryString}`;
  }
  
  onCompanySelected(e) {
    if (e.target.classList.contains('company-option')) {
      e.preventDefault();
      const companyId = e.target.getAttribute('data-id');
      
      if (this.selectedCompaniesList.includes(companyId)) {
        this.selectedCompaniesList = this.selectedCompaniesList.filter(c => c !== companyId);
        e.target.classList.remove('selected');
      } else {
        this.selectedCompaniesList.push(companyId);
        e.target.classList.add('selected');
      }
      
      this.$selectedCompanies().val(this.selectedCompaniesList.join(','));
      
      this.updateTags();
      this.updateFilterText();
    }
  }
  
  onTagSelected(e) {
    if (e.target.classList.contains('btn-tag')) {
      e.preventDefault();
      const tag = e.target.getAttribute('data-tag');

      if (this.selectedTagsList.includes(tag)) {
        this.selectedTagsList = this.selectedTagsList.filter(t => t !== tag);
      } else {
        this.selectedTagsList.push(tag);
      }
      
      this.$selectedTags().val(this.selectedTagsList.join(','));
    }
  }
  
  onClearClicked(e) {
    e.preventDefault();
    this.selectedTagsList = [];
    this.$selectedTags().val('');
    
    const params = new URLSearchParams(window.location.search);
    params.delete('filter_tags');
    params.delete('filter_companies');
    console.log(params);
    window.location.search = params.toString();
  }
  
  updateTags() {
    let allCompanyTags = [];
    
    if (this.selectedCompaniesList.length === 0) {
      this.$tagContainer().empty();
      const allAvailableTags = this.$allTagsContainer().text().split(',');
      allAvailableTags.forEach(tag => {
        this.$tagContainer().append(this.createTagButton(tag));
      });
    } else {
      this.selectedCompaniesList.forEach(companyId => {
        const companyElement = $(this.companySelectTarget).find(`.company-option[data-id='${companyId}']`);
        companyElement.attr('data-tags').split(',').forEach(tag => allCompanyTags.push(tag));
      });
      allCompanyTags = Array.from(new Set(allCompanyTags));
      
      this.$tagContainer().empty();
      allCompanyTags.forEach(tag => {
        this.$tagContainer().append(this.createTagButton(tag));
      });
    }
  }
  
  updateFilterText() {
    if (this.selectedCompaniesList.length === 0) {
      this.$companyDropdownText().text('-Select-');
    } else {
      const selectedCompanyNames = Array.from(this.selectedCompaniesList).map(cId => {
        const companyElement = $(this.companySelectTarget).find(`.company-option[data-id='${cId}']`);
        return companyElement.text();
      })
      this.$companyDropdownText().text(selectedCompanyNames.join(','));
    }
  }
  
  createTagButton(tag) {
    const tagButton = document.createElement('a');
    tagButton.href = '#';
    tagButton.className = 'btn btn-tag';
    tagButton.textContent = tag;
    
    tagButton.setAttribute('data-tag', tag);
    tagButton.setAttribute('data-affiliates--tags-target', 'tag');
    
    return tagButton;
  }
}